@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import './varibles.scss';
@import './breakpoints.scss';
@import './components/navbar.scss';
@import './components/home.scss';
@import './components/about.scss';
@import './pages/pastProject.scss';
@import './pages/contactUs.scss';
@import './components/cbh.scss';
@import './components/footer.scss';
@import './pages/pastProjectList.scss';
@import './pages/project-status.scss';

.grecaptcha-badge { visibility: hidden !important; }
body,
html {
    user-select: none;
    background: $white;
    overscroll-behavior: none;
    margin: 0;
    font-family: 'Oswald', 'Playfair Display', sans-serif, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    display: block;
    max-width:100%;
    image-rendering:-moz-crisp-edges;
    image-rendering:-o-crisp-edges;
    image-rendering:-webkit-optimize-contrast;
    image-rendering:crisp-edges;
    -ms-interpolation-mode:nearest-neighbor;

}
::-webkit-scrollbar {
    width: 8px!important;
    height: 8px !important;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #888!important;
    border-radius: 10px;
}
::-webkit-scrollbar-track {
    background: #cecece!important;
    border-radius: 10px;
}
.container-fluid {
    padding-left: 0;
    padding-right: 0;
}
.main-title{
    margin-bottom: 60px;
    h2{
        font-size: 40px;
        letter-spacing: 0.1em;
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        @media (max-width:991px) {
            font-size: 24px;
            letter-spacing: normal;
        }
    }
    @media (max-width:991px) {
        margin-bottom: 30px;
    }
}
.main__body {
    padding-left: 92px;
    @include media('<=tablet') {
        padding: 0;
    }
}
.loading-block {
    position: fixed;
    background-color: $white;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    color: #000000;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.line {
    overflow: hidden;
    display: flex;
    .word:nth-child(1) {
        animation-delay: 1.1s;
    }
    .word:nth-child(2) {
        animation-delay: 1.5s;
    }
    .word:nth-child(3) {
        animation-delay: 2s;
    }
}
.word {
    padding: 0 5px;
    opacity: 0;
    animation: slide-in 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-delay: 1s;
    animation-fill-mode: forwards;
}
@keyframes slide-in {
    from {
        transform: translateY(40px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
.call-now-btn,
.download-brochure-btn {
    margin-top: 10px;
    a{
        position: relative;
        font-size: 16px;
        line-height: 2em;
        font-weight: 400;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        padding: 8px 34px;
        cursor: pointer;
        color: #222;
        background-color: transparent;
        border: 1px solid #ddd;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        @media (max-width:767px) {
            line-height: 26px;
            padding: 8px 15px;
            font-size: 14px;
        }
        &:hover{
            background: #94d2e5;
            border-color: #94d2e5;
        }
    }
}
.building-wrapper{
    text-align: center;
    margin-bottom: 30px;
    img {
        max-width: 465px !important;
        width: 100%;
        margin: auto;
    }
}
.container {
    max-width: 1560px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    @include media('<=1560px') {
        max-width: 1280px;
    }
    @include media('<=desktop') {
        max-width: 1080px;
    }
    @include media('<=1080px') {
        width: 100%;
        min-width: 100%;
    }
    .row {
        display: flex;
        height: 100%;
        position: relative;
        .row {
            display: flex;
            height: 100%;
            padding: 0 32px;
            @include media('<=phone') {
                padding: 0 16px;
                flex-wrap: wrap;
            }
        }
        .v-center {
            align-items: center;
        }
        .space-between {
            justify-content: space-between;
        }
        .align-self-center {
            align-self: center;
            padding: 0 32px;
            @include media('<=phone') {
                padding: 0 16px;
                flex-wrap: wrap;
            }
        }
        .v-center {
            align-items: center;
        }
        .space-between {
            justify-content: space-between;
        }
        .align-self-center {
            align-self: center;
        }
    }
    .container-fluid {
        width: 100%;
        height: 100%;
        .row {
            display: flex;
            height: 100%;
        }
    }
    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }
    // @include media(">=tablet") {
    //     .col-sm-3 {
    //         width: 25%;
    //         float: left;
    //         padding: 0 15px;
    //     }
    //     .col-sm-4 {
    //         width: 33.33333333%;
    //         float: left;
    //         padding: 0 15px;
    //     }
    //     .col-sm-5 {
    //         width: 41.66666667%;
    //         float: left;
    //         padding: 0 15px;
    //     }
    //     .col-sm-6 {
    //         width: 50%;
    //         float: left;
    //         padding: 0 15px;
    //     }
    //     .col-sm-7 {
    //         width: 58.33333333%;
    //         float: left;
    //         padding: 0 15px;
    //     }
    // }
    img {
        max-width: 100%;
        display: block;
    }
    .unstyled {
        -webkit-appearance: none;
    }

    .unstyled::-webkit-inner-spin-button,
    .unstyled::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    .button__border {
        position: absolute;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        .top__border {
            position: absolute;
            background-color: #fff;
            -webkit-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            -o-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            top: -1px;
            right: 0;
            width: 0;
            height: 1px;
        }
        .bottom__border {
            position: absolute;
            background-color: #fff;
            -webkit-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            -o-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            left: 0;
            bottom: -1px;
            width: 0;
            height: 1px;
        }
        .left__border {
            position: absolute;
            background-color: #fff;
            -webkit-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            -o-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            left: -1px;
            bottom: 0;
            width: 1px;
            height: 0;
        }
        .right__border {
            position: absolute;
            background-color: #fff;
            -webkit-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            -o-transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            transition: 0.5s cubic-bezier(0.71, 0.29, 0.18, 0.97);
            top: 0;
            right: -1px;
            width: 1px;
            height: 0;
        }
    }
}

.bg-video-block {
    position: relative;
    padding-bottom: 50px;
    @media (max-width:991px) {
        padding-bottom: 30px;
    }
    .bg-video-wrap {
        position: relative;
        overflow: hidden;
        width: 100%;
        // height: 70vh;
        border: 1px solid #222222;
        background-color: rgba(0, 0, 0, 0.5);
        // max-width: 750px;
        margin: auto;
        @media (max-width:991px) {
            height: auto;
        }
    }
    video {
        z-index: 1;
        // position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        @media (max-width:991px) {
            position: relative;
        }
    }
    .play-btn {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
    }
    .play-btn svg {
        fill: none;
        stroke: rgba(255, 255, 255, 0.3);
        stroke-width: 2;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        position: relative;
        z-index: -1;
        @include media("<=phone") {
            width: 70px;
            height: 70px;
        }
    }
    .play-btn .video-button-stroke {
        fill: none;
        stroke: rgba(255, 255, 255, 0.3);
        stroke-width: 2;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        position: relative;
        z-index: -1;
    }
    .play-btn .video-button-circle {
        fill: none;
        stroke: #fff;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        stroke-dashoffset: 700;
        stroke-dasharray: 700;
        stroke-width: 2;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-linecap: butt;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transition: all 1.5s;
        -o-transition: all 1.5s;
        transition: all 1.5s;
    }
    .play-btn:hover .video-button-circle {
        stroke-dashoffset: 0;
        stroke-dasharray: 700;
        -webkit-transition: all 2s cubic-bezier(0.46, 0.08, 0.12, 0.91);
        -o-transition: all 2s cubic-bezier(0.46, 0.08, 0.12, 0.91);
        transition: all 2s cubic-bezier(0.46, 0.08, 0.12, 0.91);
    }
}
.view-image-block {
    #ReactSimpleImageViewer {
        z-index: 1000;
    }
}
.gallery-details-block{
    .slick-slide{
        padding: 0 5px;
    }
    .gallery-slider {
        position: relative;
    }
    .slick-slider {
        .slick-arrow{
            width: auto;
            height: auto;
            &::before{
                color: #000000;
                font-size: 30px;
            }
        }
        .slick-next{
            right: 0;
        }
        .slick-prev{
            left: 0;
        }
    }
    .image-item{
        height: 250px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
        }
    }
    // @media (min-width:992px) {
    //     .gallery-slider{
    //         margin: -50px 0;
    //     }
    //     .slick-list{
    //         padding: 50px 0;
    //     }
    // }
}
.amenities-details-block{
    .amenities-grid-block{
        display: grid;
        grid-gap: 15px;
        overflow-y: auto;
        padding-bottom: 15px;
        @media (max-width:767px) {
            grid-gap: 10px;
        }
        .inner-item{
            display: flex;
            // flex-direction: column;
            grid-gap: 15px;
            @media (max-width:767px) {
                grid-gap: 10px;
            }
        }
        .card-item{
            background-color:#eeeeee;
            padding:15px;
            min-width: 371px;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media (max-width:991px) {
                min-width: 300px;
            }
            @media (max-width:575px) {
                min-width: 190px;
            }
            @media (max-width:400px) {
                min-width: 140px;
            }
            .card-image{
                flex: none;
                margin-bottom: 15px;
                img{
                    width: 60px;
                    margin: auto;
                    filter: invert(1);
                }
            }
            .card-content{
                h5{
                    font-size: 18px;
                    @media (max-width:767px) {
                        font-size: 16px;
                     }
                }
                .dots-list {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li{
                        font-size: 17px;
                        @media (max-width:767px) {
                           font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}