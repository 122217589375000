.project-status-timeline{
    padding: 50px 0;
    .project-status-content{
        .project-status {
            @media(min-width:768px){
                position: absolute;
                background-color: rgb(237 237 237 / 88%);
                padding: 20px 30px;
                bottom: 20px;
                left: -70px;
                right:0;
                @media (max-width:991px) {
                    padding: 15px;
                }
            }
            p{
                margin: 0;
                font-size: 19px;
                font-family: 'Playfair Display';
                @media (max-width:991px) {
                    font-size: 14px;
                }
            }
        }
    }
    .timeline-status:nth-child(odd){
        border-left: 2px solid #67a9ef;
    }
    .timeline-status:nth-child(even) .row{
        display: flex;
        flex-direction: row-reverse;
        .project-status{
            right: -70px;
            left: 0;
        }
    }
    .timeline-status{
        padding: 20px;
        &:not(:last-child){
            border-bottom: 2px solid #67a9ef;
        }
        @media (max-width:991px) {
            max-width: 400px;
            margin: 0 auto;
        }
    }
    .timeline-status:nth-child(even){
        border-right: 2px solid #67a9ef;
    }
    .timeline-status:nth-child(even) .timeline-year {
        position: absolute;
        top: 20%;
        color: #67a9ef;
        right: -55px;
        width: 35px;
        font-weight: bold;
        @media(max-width:767px){
            top: -33px;
            left: auto;
            right: 40px;
            background-color: #fff;
            padding-right: 10px;
            width: auto;
            display: flex;
            align-items: center;
            width: auto;
        }
        &:before{
            position: absolute;
            content: "";
            left: -18px;
            top: 8px;
            width: 8px;
            height: 8px;
            display: inline-block;
            background-color: #67a9ef;
            border-radius: 15px;
            @include media('<=phone'){
                position: unset;
                vertical-align: middle;
                flex-shrink: 0;
                margin-right: 10px;
            }
        }
    }
    .timeline-status:nth-child(odd) .timeline-year {
        position: absolute;
        top: 20%;
        color: #67a9ef;
        left: -57px;
        width: 35px;
        font-weight: bold;
        @media(max-width:767px){
            top: -33px;
            left: 75px;
            background-color: #fff;
            display: flex;
            align-items: center;
            width: auto;
            padding-right: 10px;
        }
        &:before{
            position: absolute;
            content: "";
            right: -20px;
            top: 8px;
            width: 8px;
            height: 8px;
            display: inline-block;
            background-color: #67a9ef;
            border-radius: 15px;
            @media(max-width:767px){
                position: unset;
                vertical-align: middle;
                flex-shrink: 0;
                margin-right: 10px;
            }
        }
    }
    @media(max-width:767px){
        .timeline-status{
            position: relative;
        }
        .timeline-status:first-child:before{
            content: "";
            background-color: #67a9ef;
            position: absolute;
            left: -2px;
            top: -2px;
            width: 85px;
            height: 2px;
        }
        .timeline-status .project-status-content{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgb(0 0 0 / 52%);
            max-width: unset;
            flex: unset;
            max-width: 85%;
            margin: auto;
            max-height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease-in-out;
            transform: scale(0.7);
            opacity: 0;
            visibility: hidden;
            
            p{
                font-size: 16px;
                color: #fff;
                text-align: justify;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
            }
        }
        .timeline-status:hover  .project-status-content{
            transform: scale(1);
            opacity: 1;
            visibility: visible;
        }
    }
    
}
@media (max-width:1024px){
    .project-status-timeline .container{
        max-width: 700px;
        width: auto;
        min-width: auto;
    }
}
@media  (min-width:768px) and (max-width:991px){
    .project-status-timeline .timeline-status:nth-child(odd) .timeline-year{
        left: -52px;
        text-align: right;
    }
    .project-status-timeline .timeline-status:nth-child(even) .timeline-year{
        right: -52px;
        text-align: left;
    }
    .project-status-timeline .timeline-status:nth-child(odd) .timeline-year:before{
        right: -15px;
    }
    .project-status-timeline .timeline-status:nth-child(even) .timeline-year:before{
        left: -15px;
    }
}