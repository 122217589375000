.past-project-list {
    padding: 40px 0;
    .past-project-page-title {
        p {
            @include media('<=phone') {
                font-size: 13px;
                margin-bottom: 5px;
            }
        }
        h4 {
            @include media('<=phone') {
                font-size: 15px;
                margin: 0;
            }
        }
    }
    .past-project-backto-link {
        align-self: center;
        text-align: right;
        a {
            color: #000;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;
            @include media('<=phone') {
                font-size: 12px;
            }
            &:hover {
                color: #9abcc9
            }
        }
    }
    .past-project-main-img {
        margin-top: 30px;
        position: relative;
        margin-bottom: 150px;
        @include media('<=tablet') {
            margin-bottom: 30px;
        }
        .past-project-main-content {
            position: absolute;
            left: 8%;
            bottom: 15%;
            max-width: 60%;
            @include media('<=phone') {
                max-width: 100%;
                left: 20px;
                bottom:20px;
            }
            h2 {
                font-family: 'Playfair Display';
                font-size: 25px;
                line-height: 35px;
                font-weight: 400;
                color: #ffffff;
                @include media('<=767px') {
                    font-size: 16px;
                    line-height: 22px;
                }
                &:before {
                    position: absolute;
                    top: -45px;
                    left: 0;
                    width: 70px;
                    height: 2px;
                    background-color: #fff;
                    content: '';
                    @include media('<=phone') {
                        top: -25px;
                    }
                }
            }
        }
    }
    .list-tabs {
        text-align: center;
        margin-bottom: 50px;
        button {
            border: none;
            outline: none;
            box-shadow: none;
            background-color: transparent;
            padding: 15px 20px;
            position: relative;
            border-bottom: 2px solid #d6d6d6;
            @include media('<=phone') {
                padding: 10px;
            }
            &:after {
                content: '';
                display: block;
                height: 2px;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 0%;
                opacity: 0;
                z-index: 999;
                background-color: #000;
                -webkit-transition: width .2s ease-in-out;
                -o-transition: width .2s ease-in-out;
                transition: width .2s ease-in-out;
            }
            &.active:after,
            &:hover:after {
                width: 100%;
                opacity: 1;
            }
            span {
                color: #000;
                font-family: Oswald, sans-serif;
                font-size: 16px;
                line-height: 35px;
                font-weight: 400;
                letter-spacing: .25em;
                text-transform: uppercase;
                @include media('<=phone') {
                    font-size: 14px;
                    letter-spacing: 0.15em;
                }
            }
        }
    }
    .tab-items {
        margin-bottom: 40px;
        .tab-content {
            margin-top: 15px;
            p {
                margin-bottom: 8px;
                font-family: "Playfair Display", sans-serif;
                font-size: 14px;
                font-weight: 600;
                line-height: 23px;
                color: #000000;
            }
            h2 {
                margin: 0 0 10px;
                color: #222;
                letter-spacing: .1em;
                font-weight: 600;
                font-size: 16px;font-family: "Playfair Display", sans-serif;
            }
            h4{
                font-size: 16px;font-family: "Playfair Display", sans-serif;
                font-weight: 600;color: #222;
                letter-spacing: .1em;
                margin: 0;
            }
        }
    }

}
.project-list-tabs {
    .tab-items {
        img{
            height: 280px;
            width: 100%;
            object-fit: cover;
            @media (max-width:767px) {
                height: 190px;
            }
        }
        .tab-content{
            h2{
                font-size: 16px;
                span {
                    font-size: 18px;
                }
            }
        }
    }
}