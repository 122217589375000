.download-brochure{
    position: fixed;
    top: 30%;
    right: -50px;
    background-color: transparent;
    width: auto;
    transform: rotate(90deg);z-index: 999;margin-bottom: 20px;
    max-width: 100%;box-shadow: 2px -1px 40px 0 rgba(65, 61 ,61 , 0.7);
    @media (max-width:991px) {
        top: 50%;
    }
    a{
        background-color: #ffffff;
        padding: 14px 20px;
        color: #222222;
        text-decoration: none;
    }
}
.gallery-details{
    padding: 50px 0;
}
.floor-details {
    padding: 50px 0;
    @media (max-width:991px) {
        padding: 30px 0;
    }
    .floor-plans-images {
        img{
            margin: auto;
        }
        @media (max-width:767px) {
           margin-top: 30px;
        }
    }
    .container {
        .row {
            padding: 0 !important;
            position: relative;
        }
        .even {
            flex-direction: row-reverse;
            .floor-plans-images {
                transform: rotateY(180deg);
            }
            .floor-plans {
                text-align: right;
            }
            h1 {
                left: auto;
                right: 15px;
            }
        }
    }
    .floor-tabs {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        @include media('<=tablet') {
            overflow-y: auto;
        }
        .custom-toggle {
            width: 33.33%;
            padding: 20px 10px;
            background-color: transparent;
            text-align: center;
            border: 1px solid #edf7fa;
            cursor: pointer;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            @include media('<=tablet') {
                width: 25%;
            }
            @include media('<=767px') {
                width: 33.33%;
                padding: 10px;
            }
            @include media('<=575px') {
                width: 50%;
            }
            img{
                width: 25px;
                @include media('<=575px') {
                    display: none;
                }
            }
            span {
                margin-left: 10px;
                font-size: 16px;
                text-transform: uppercase;
                @include media('<=575px') {
                    margin-left: 0;
                }
            }
            
        }
        // .custom-toggle:not(:first-child) {
        //     border-left: none;
        // }
    }
    .floor-plans {
        position: unset;
        h3 {
            font-size: 40px;
            @include media('<=desktop') {
                font-size: 34px;
            }
            @media(max-width:375px) {
                font-size: 25px;
            }
        }
        p {
            font-size: 25px;
        }
        h1 {
            font-size: 130px;
            color: #edf7fa;
            text-align: left;
            position: absolute;
            left: 15px;
            bottom: -20px;
            line-height: 1;
            font-weight: 700;
            letter-spacing: 4px;
            @include media('<=desktop') {
                font-size: 100px;
            }
            @include media('<=tablet') {
                font-size: 90px;
                bottom: -30px;
            }
            @include media('<=phone') {
                font-size: 40px;
                bottom: -14%;
            }
        }
    }
    .floor-detail {
        .area-detail {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
            margin-bottom: 15px;
        }
        span {
            font-size: 17px;
        }
        p {
            font-size: 15px;
            margin: 0 0 0 10px;
        }
    }
}