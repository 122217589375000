#footer_bottom {
    background-color: #19130b;
    @media(min-width:992px) {
        margin-left: 92px;
    }
    .row {
        padding: 0;
    }
    .footer-title1 {
        h3{
            color: #fff;
            font-family: "Playfair Display", serif;
            font-size: 40px;
            font-weight: 400;
            letter-spacing: 2px;
            @media (max-width:991px) {
                font-size: 24px;
            }
        }
    }
    .footer-link-contact {
        padding: 100px 0;
        @media(max-width:991px) {
            padding: 40px 0 10px;
        }
        .footer-link {
            @media(max-width:991px) {
                margin-bottom: 30px;
            }
            .footer-title {
                margin-bottom: 30px;
                @media(max-width:991px) {
                    margin-bottom: 20px;
                }
            }
            .footer-title h3 {
                color: #fff;
                font-family: "Playfair Display", serif;
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 2px;
            }
            .footer-content a {
                display: block;
                color: #b2b2b2;
                font-family: "Playfair Display", serif;
                text-decoration: none;
                margin-bottom: 15px;
                font-size: 16px;
                &:hover {
                    color: #fff;
                }
            }
            .footer-content p {
                font-family: "Playfair Display", serif;
                color: #fff;
                line-height: 1.5;
                a {
                    color: #b2b2b2;
                }
            }
            .footer-content .form-control:focus,
            .footer-content .form-control:hover {
                border-color: #fff;
                outline: none;
                box-shadow: none;
            }
            .footer-content .form-control:focus::placeholder,
            .footer-content .form-control:hover::placeholder {
                color: #fff;
            }
            .footer-content .form-control::placeholder {
                letter-spacing: 4px;
                font-size: 15px;
            }
            .footer-content .form-control {
                padding: 0;
                background-color: transparent;
                outline: none;
                border: none;
                border-bottom: 1px solid #474747;
                border-radius: 0;
                cursor: pointer;
                color: #fff;
            }
            .footer-content button {
                padding: 0;
                border: none;
                background-color: transparent;
                outline: none;
            }
            .footer-content button svg circle:nth-child(2) {
                stroke-dasharray: 150;
                stroke-dashoffset: 150;
                -webkit-transition: all 1.5s cubic-bezier(.45, 0, .14, 1.03);
                -o-transition: all 1.5s cubic-bezier(.45, 0, .14, 1.03);
                transition: all 1.5s cubic-bezier(.45, 0, .14, 1.03);
                -webkit-transform: rotate( -200deg);
                -ms-transform: rotate(-200deg);
                transform: rotate( -200deg);
                -webkit-transform-origin: 71% 50%;
                -ms-transform-origin: 71% 50%;
                transform-origin: 71% 50%;
            }
            .footer-content button:hover svg circle:nth-child(2) {
                stroke-dashoffset: 0;
                -webkit-transform: rotate( 55deg);
                -ms-transform: rotate(55deg);
                transform: rotate( 55deg);
                -webkit-transition: 1.2s cubic-bezier(.38, .67, .76, .66);
                -o-transition: 1.2s cubic-bezier(.38, .67, .76, .66);
                transition: 1.2s cubic-bezier(.38, .67, .76, .66);
            }
        }
    }
    .copyright {
        border-top: 1px solid #363636;
        .copyright-text {
            text-align: center;
            p {
                margin: 0;
                padding: 10px 0;
                letter-spacing: 5px;
                font-size: 12px;
                color: #6f6d6b;
                @include media("<=phone") {
                    letter-spacing: normal;
                }
                a {
                    color: #b2b2b2;
                    text-decoration: none;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}