.nav__wrapper {
  position: fixed;
  width: 92px;
  top: 0;
  left: 0;
  height: calc(100%);
  background: $white;
  box-shadow: 2px -1px 40px 0 rgb(65 61 61 / 7%);
  transition: 0.3s ease-in-out;
  z-index: 99;
  @include media("<=tablet") {
    position: sticky;
    height: auto;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .hamburger__icon_active {
    span {
      transform: rotate(45deg);
      margin: 0 !important;
      &:nth-child(2) {
        width: 100% !important;
        transform: rotate(-45deg);
      }
      @media (max-width: 768px) {
        transform: rotate(45deg) translateX(1px);
      }
    }
  }
  .hamburger__icon {
    width: 92px;
    height: 92px;
    cursor: pointer;
    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
    .ham__adjust {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 40%;
      margin: auto;

      span {
        width: 100%;
        height: 2px;
        background: $black;
        display: block;
        margin-bottom: 8px;
        transition: 0.3s ease-in-out;
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(2) {
          width: 50%;
        }
      }
    }
    &:hover {
      .ham__adjust {
        span {
          width: 100%;
        }
      }
    }
  }
  .logo__wrapper {
    display: block;
    width: 92px;
    height: auto;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    a {
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
    img {
      max-height: 65px;
      margin: 15px auto 0;
    }
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      flex: auto;
      padding-left: 15px;
      img {
        margin: 0 0 0 15px;
        max-height: 40px;
      }
    }
  }
  .enquiry__wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-50%);
    height: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -15px;
    width: 280px;
    background: $secondary;
    transition: 0.2s ease-in-out;
    @include media("<=tablet") {
      display: none;
    }
    span {
      margin-left: 60px;
      font-size: 12px;
      letter-spacing: 0.25em;
      text-transform: uppercase;
    }
  }
  .menu__wrapper {
    display: none;
    opacity: 0;
    transition: 0.4s ease-in-out;
  }
}

.nav__wrapper.active {
  width: 500px;
  height: 100%;
  @include media("<=tablet") {
    width: auto;
    height: auto;
  }
  .hamburger__icon {
    z-index: 999;
    position: relative;
  }
  .menu__wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 500px;
    padding: 4% 3% 2%;
    opacity: 1;
    box-sizing: border-box;
    height: 100vh;
    overflow-x: auto;
    z-index: 5;
    @include media("<=tablet") {
      width: 100%;
      position: absolute;
      padding: 20px;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #fff;
      display: block;
      grid-template-rows: unset;
      height: auto;
      box-shadow: 1px 6px 9px #0000001f;
    }
    .logo__full {
      display: flex;
      align-items: center;
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 30px;
      flex-direction: column;
      img {
        max-height: 70px;
        margin-bottom: 15px;
        @media (max-width: 767px) {
          max-height: 60px;
        }
      }
      @include media("<=tablet") {
        display: none;
      }
      a {
        color: $black;
        &:hover {
          color: black;
          text-decoration: none;
        }
      }
    }
    nav {
      // flex: 1 1 auto;
      @include media("<=tablet") {
        margin: 0;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          margin-bottom: 20px;
          a {
            color: #000;
            text-transform: uppercase;
          }
        }
      }
      .submenu-link {
        li {
          margin-bottom: 5px;
          a {
            position: relative;
            text-transform: capitalize;
            font-family: "Playfair Display", sans-serif;
            transition: all 0.3s ease-in-out;
            &:hover {
              padding-left: 15px;
              text-decoration: none;
            }
            &:after {
              content: "";
              position: absolute;
              left: 0;
              height: 2px;
              background-color: #000;
              top: 10px;
              transition: all 0.3s ease-in-out;
              width: 0;
            }
            &:hover:after {
              width: 7px;
            }
          }
        }
      }
    }
    .menu__footer {
      display: flex;
      flex-direction: column;
      @include media("<=tablet") {
        display: none;
      }
      span {
        font-family: "Playfair Display", serif;
      }
      .social__footer {
        margin-top: 10px;
        span {
          font-size: 15px;
          font-weight: 700;
          margin-right: 45px;
          position: relative;
          &::after {
            content: "";
            background: silver;
            height: 1px;
            width: 26px;
            position: absolute;
            top: 55%;
            right: -33px;
          }
        }
      }
    }
  }
  .logo__wrapper {
    transform: translate(-92px, 0px);
    @include media("<=tablet") {
      transform: none;
    }
  }
  .enquiry__wrapper {
    left: -92px;
  }
}
.download-call-btn {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .download-brochure-btn {
    margin: 0;
  }
  .call-now-btn {
    margin: 0 0 0 10px;
  }
}
.enquiryForm__wrapper {
  position: fixed;
  width: 100%;
  max-width: 500px;
  top: 0;
  left: -500px;
  height: calc(100%);
  background: $white;
  box-shadow: 2px -1px 40px 0 rgb(65 61 61 / 7%);
  transition: 0.3s ease-in-out;
  z-index: 100;
  overflow-y: auto;
  .hamburgerEnquiry__icon.active {
    width: 92px;
    height: 92px;
    cursor: pointer;
    .hamEnquiry__adjust {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 40%;
      margin: auto;
      span {
        width: 100%;
        height: 2px;
        background: $black;
        display: block;
        margin-bottom: 0px;
        transition: 0.3s ease-in-out;
        transform: rotate(45deg) translate(0%, 50%);
        &:nth-child(2) {
          transform: rotate(-45deg) translate(0%, -50%);
        }
      }
    }
  }
  .hamburgerEnquiry__icon {
    width: 92px;
    height: 92px;
    cursor: pointer;
    .hamEnquiry__adjust {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 40%;
      margin: auto;
      span {
        width: 100%;
        height: 2px;
        background: $black;
        display: block;
        margin-bottom: 8px;
        transition: 0.3s ease-in-out;
        transform: rotate(0deg) translate(0%, 0%);
      }
    }
  }
}

.enquiryForm__wrapper.active {
  left: 0px;
}

.enquiry-form-wrapper {
  padding: 40px;
  @media (max-width: 767px) {
    padding: 30px 20px 40px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
