.banner__wrapper {
    background-image: url("../../assets/pastbanner.jpg");
    width: calc(100vw - 92px);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media("<=tablet") {
        width: 100vw;
    }
    .banner__detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300px;
        background: rgba(0, 0, 0, 0.6);
        .pastProject__banner__title {
            font-weight: 500;
            font-size: 16px;
            color: rgb(137, 243, 137);
            font-style: italic;
            display: inline-block;
            height: 50px;
            margin-top: 50px;
            letter-spacing: 2px;
            @include media("<=phone") {
                margin-top: 20px;
                height: 40px;
            }
        }
        .pastProject__banner__subTitle {
            font-weight: 500;
            font-size: 36px;
            color: $white;
            display: inline-block;
            height: 50px;
            width: 500px;
            text-align: center;
            @include media("<=phone") {
                height: auto;
                font-size: 20px;
            }
        }
        .pastProject__banner__button {
            margin: 50px 0px;
            height: 50px;
            width: 150px;
            font-size: 12px;
            letter-spacing: 2px;
            background: #8de0fc;
            border: none;
            color: $white;
            @include media("<=phone") {
                margin: 10px 0px;
                height: 40px;
                width: 130px;
            }
        }
    }
}

.pastProject__wrapper {
    overflow: hidden;
    margin-bottom: 170px;
    @include media("<=tablet") {
        margin-bottom: 100px;
    }
}

.introPastProject__wrapper {
    margin-top: 6.667rem;
    @include media("<=phone") {
        margin-top: 50px;
    }
    .mainIntro__wrapper {
        width: 100%;
        display: flex;
        @include media("<=phone") {
            flex-wrap: wrap;
            flex-direction: column;
        }
        .leftIntro__wrapper {
            @include media(">tablet") {
                width: calc(50% - 20px);
                padding-right: 20px;
            }
            .leftIntro__title {
                color: #3fb9be;
                font-size: 1.2667em;
                line-height: 1.2em;
                font-style: italic;
                text-transform: none;
                letter-spacing: 0;
                font-weight: 400;
            }
            h2 {
                font-size: 2.6667rem;
                line-height: 1.1818em;
                margin-top: 0.6em;
                color: #13162b;
                letter-spacing: 2px;
                font-weight: 400;
                font-style: normal;
                @include media("<=tablet") {
                    font-size: 2.1rem;
                }
                @include media("<=phone") {
                    font-size: 25px;
                }
            }
            .leftIntro__para {
                font-size: 1em;
                padding-left: 4.6rem;
                margin-top: 3rem;
                margin-bottom: 0;
                @include media("<=tablet") {
                    padding-left: 0;
                }
                @include media("<=phone") {
                    margin-top: 2rem;
                    padding-left: 0;
                }
            }
        }
        .leftIntro__wrapper {
            padding-right: 20px;
        }
        .rightIntro__wrapper {
            width: 50%;
            flex: 0 0 50%;
            .rightIntro__image {
                background-image: url("../../assets/pastIntro.jpg");
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                top: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .introStats__wrapper {
        height: 150px;
        width: 100%;
        background-color: #13162b;
        margin: 50px 0px;
        display: flex;
        @include media("<=phone") {
            display: block;
            height: auto;
        }
        .introStats__data {
            width: 25%;
            border: 1px solid white;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 3%;
            @include media("<=phone") {
                width: 100%;
                height: 150px;
            }
            span {
                color: $white;
                font-size: 36px;
                font-weight: 400;
            }
            h6 {
                margin: 0;
                font-style: italic;
                color: $white;
                letter-spacing: 2px;
                font-size: 18px;
            }
        }
    }
    .horizontal__wrapper {
        .image__horizontal {
            display: flex;
            // justify-content: space-between;
            margin: 50px 0px;
            .panel {
                position: relative;
                min-width: 600px;
                height: 400px;
                img {
                    width: 600px;
                    height: 100%;
                    margin-right: 5px;
                    opacity: 0.9;
                }
                .image__data {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    color: $white;
                    width: 100%;
                    height: 100%;
                    span {
                        font-size: 40px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}