@mixin animation-mixin($name) {
  @keyframes #{$name} {
    0% {
      transform: translateX(-5px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
}

@include animation-mixin(dataAnimation);
.home__wrapper {
  overflow: hidden;
  .download-brochure-btn {
    margin-left: 10px;
    a {
      position: relative;
      font-size: 16px;
      line-height: 2em;
      font-weight: 400;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out;
      padding: 8px 34px;
      cursor: pointer;
      color: #222;
      background-color: transparent;
      border: 1px solid #ddd;
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      font-family: "Playfair Display", sans-serif;
      @media (max-width: 991px) {
        line-height: 26px;
        padding: 8px 15px;
        font-size: 14px;
      }
    }
  }
  .banner__hover__wrapper {
    position: relative;
    .main__hover__img {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .floor__data__wrapper {
      position: absolute;
      left: 20px;
      bottom: 100px;
      min-width: 271px;
      padding: 30px;
      background-color: $white;
      background-image: url("../../assets/pattern.png");
      @include media("<=1440px") {
        bottom: 50px;
      }
      @include media("<=768px") {
        left: 50px;
        padding: 10px;
      }
      @include media("<=767px") {
        left: 10px;
        bottom: 10px;
        min-width: auto;
      }
      * {
        & ~ :empty {
          display: none;
        }
      }
      .floor__data__title {
        margin: 0;
        opacity: 0;
        text-transform: capitalize;
        animation: dataAnimation 0.8s ease forwards;
        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
      .floor__data__subData {
        margin: 15px 0 0 0;
        line-height: 1em;
        color: #c28562;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
        opacity: 0;
        -ms-word-wrap: break-word;
        word-wrap: break-word;
        animation: dataAnimation 0.8s ease forwards;
        animation-delay: 0.2s;
        @media (max-width: 767px) {
          font-size: 12px;
          margin-top: 7px;
        }
      }
      .floor__data__sub {
        margin: 10px 0 0 0;
        line-height: 1.45em;
        font-weight: 500;
        opacity: 0;
        text-transform: capitalize;
        animation: dataAnimation 0.8s ease forwards;
        animation-delay: 0.4s;
        @media (max-width: 767px) {
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      polygon {
        cursor: pointer;
      }
    }
  }
  .banner__wrapper {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    .banner__working {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include media("<=tablet") {
        height: auto;
        padding: 100px 0;
      }
      @include media("<=phone") {
        height: auto;
        padding: 50px 0;
      }
      .banner__title {
        font-weight: 400;
        font-size: 100px;
        color: $white;
        letter-spacing: 10px;
        @include media(">tablet", "<desktop") {
          font-size: 60px;
        }
        @include media("<=tablet") {
          font-size: 50px;
          letter-spacing: 5px;
        }
        @include media("<=phone") {
          font-size: 20px;
          letter-spacing: 5px;
        }
      }
      .banner__desc {
        font-size: 26px;
        color: $white;
        text-align: center;
        @include media(">=desktop") {
          width: 50%;
        }
        @include media("<=tablet") {
          font-size: 20px;
          width: auto;
          padding: 0 20px;
        }
        @include media("<=phone") {
          font-size: 14px;
          width: auto;
          padding: 0 10px;
        }
      }
      .banner__button {
        font-size: 26px;
        width: 200px;
        height: 50px;
        background: none;
        border: 1px solid $white;
        color: $white;
        @include media("<=phone") {
          font-size: 17px;
          width: 160px;
          height: 40px;
        }
      }
    }
  }
  .interiors {
    padding: 150px 0 200px;
    position: relative;
    @include media("<=tablet") {
      padding: 40px 0;
    }
    .left-image-slider {
      padding: 0 40px 0 60px;
      @include media("<=tablet") {
        padding: 0 15px;
        margin-bottom: 25px;
      }
    }
    @include media("<=tablet") {
      .col-sm-6 {
        padding: 0 15px !important;
      }
    }
    .right-content-text h2 {
      font-family: "Oswald", sans-serif;
      font-size: 40px;
      margin: 0;
      font-weight: 400;
      letter-spacing: 3px;
      @include media("<=tablet") {
        font-size: 30px;
        letter-spacing: 1px;
      }
      @include media("<=phone") {
        font-size: 20px;
      }
    }
    .right-content-text h2:after {
      content: "";
      border-color: #000000;
      width: 40px;
      border-width: 1px 0 0 0;
      display: block;
      border-style: solid;
      margin-top: 20px;
    }
    .right-content-text p {
      margin: 20px 0 0 0;
      font-family: "Playfair Display", sans-serif;
      font-size: 17px;
      line-height: 26px;
      @include media("<=phone") {
        font-size: 14px;
      }
    }
    .content-lists {
      margin-top: 35px;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .content-lists span {
      font-family: "Playfair Display", sans-serif;
      margin-bottom: 10px;
      display: block;
      padding-left: 20px;
      position: relative;
    }
    .content-lists span:before {
      content: "";
      position: absolute;
      left: 0;
      width: 6px;
      height: 6px;
      background-color: #000;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 2px;
    }
    .more-info-btn {
      position: relative;
      font-size: 16px;
      line-height: 2em;
      font-weight: 400;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out;
      padding: 8px 34px;
      cursor: pointer;
      color: #222;
      background-color: transparent;
      border: 1px solid #ddd;
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      font-family: "Playfair Display", sans-serif;
      @media (max-width: 991px) {
        line-height: 26px;
        padding: 8px 13px;
        font-size: 14px;
      }
    }
    .more-info-btn svg {
      max-height: 15px;
      transform: translateY(-2px);
      transition: 0.2s;
      transition-delay: 0.2s;
    }
    .more-info-btn:hover svg {
      opacity: 0;
      -webkit-transform: translateY(-40%);
      -ms-transform: translateY(-40%);
      transform: translateY(-40%);
      -webkit-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
    }
    .more-info-btn .btn-icon {
      position: relative;
      margin-right: 10px;
    }
    .more-info-btn .btn-icon:after {
      content: "";
      position: absolute;
      background: #bbb;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -10%);
      -ms-transform: translate(-50%, -10%);
      transform: translate(-50%, -10%);
      opacity: 0;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
    }
    .more-info-btn:hover .btn-icon:after {
      opacity: 1;
      -webkit-transition-delay: 0.1s;
      -o-transition-delay: 0.1s;
      transition-delay: 0.1s;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .more-info {
      margin-top: 32px;
      display: flex;
      align-items: center;
      .download-brochure-btn {
        margin-top: 0;
      }
    }
    .bottom-right-shap {
      position: absolute;
      display: block;
      z-index: 4;
      width: 50%;
      height: 100%;
      overflow: hidden;
      -webkit-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
      right: 0;
      bottom: 0;
      background: -webkit-gradient(
        linear,
        left top,
        right bottom,
        color-stop(50%, transparent),
        color-stop(50%, #edf7fa)
      );
      background: -o-linear-gradient(top left, transparent 50%, #edf7fa 50%);
      background: linear-gradient(
        to bottom right,
        transparent 50%,
        #edf7fa 50%
      );
      @include media("<=phone") {
        // width: 100%;
      }
    }
  }
  .bottom-animation-text {
    font-size: 180px;
    color: #edf7fa;
    text-align: left;
    position: absolute;
    bottom: 6%;
    left: 6%;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 4px;
    @include media("<=tablet") {
      font-size: 120px;
    }
    @include media("<=phone") {
      font-size: 50px;
      bottom: 3%;
    }
  }
  .interiors .container {
    width: 100%;
    z-index: 6;
    position: relative;
  }
  .Main-video {
    position: relative;
    text-align: center;
    .bg-video-wrap {
      position: relative;
      overflow: hidden;
      display: inline-block;
      height: 100vh;
      border: 1px solid #222222;
      @media (max-width: 991px) {
        height: auto;
      }
    }
    video {
      min-height: 100vh;
      z-index: 1;
      // position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      @media (max-width: 991px) {
        min-height: auto;
        position: relative;
      }
    }
    .play-btn {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
    }
    .play-btn svg {
      fill: none;
      stroke: rgba(255, 255, 255, 0.3);
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      position: relative;
      z-index: -1;
      @include media("<=phone") {
        width: 70px;
        height: 70px;
      }
    }
    .play-btn .video-button-stroke {
      fill: none;
      stroke: rgba(255, 255, 255, 0.3);
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      position: relative;
      z-index: -1;
    }
    .play-btn .video-button-circle {
      fill: none;
      stroke: #fff;
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      stroke-dashoffset: 700;
      stroke-dasharray: 700;
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
      stroke-linecap: butt;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transition: all 1.5s;
      -o-transition: all 1.5s;
      transition: all 1.5s;
    }
    .play-btn:hover .video-button-circle {
      stroke-dashoffset: 0;
      stroke-dasharray: 700;
      -webkit-transition: all 2s cubic-bezier(0.46, 0.08, 0.12, 0.91);
      -o-transition: all 2s cubic-bezier(0.46, 0.08, 0.12, 0.91);
      transition: all 2s cubic-bezier(0.46, 0.08, 0.12, 0.91);
    }
  }
  .building__imageOne__wrapper {
    height: 100%;
    overflow: hidden;
  }
  .building {
    position: relative;
    padding: 100px 0;
    @media (max-width: 991px) {
      padding: 50px 0;
    }
    .more-info-btn {
      position: relative;
      font-size: 16px;
      line-height: 2em;
      font-weight: 400;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out;
      padding: 8px 34px;
      cursor: pointer;
      color: #222;
      background-color: transparent;
      border: 1px solid #ddd;
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      font-family: "Playfair Display", sans-serif;
    }
    .more-info-btn svg {
      max-height: 15px;
      transform: translateY(-2px);
      transition: 0.2s;
      transition-delay: 0.2s;
    }
    .more-info-btn:hover svg {
      opacity: 0;
      -webkit-transform: translateY(-40%);
      -ms-transform: translateY(-40%);
      transform: translateY(-40%);
      -webkit-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
    }
    .more-info-btn .btn-icon {
      position: relative;
      margin-right: 10px;
    }
    .more-info-btn .btn-icon:after {
      content: "";
      position: absolute;
      background: #bbb;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -10%);
      -ms-transform: translate(-50%, -10%);
      transform: translate(-50%, -10%);
      opacity: 0;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
    }
    .more-info-btn:hover .btn-icon:after {
      opacity: 1;
      -webkit-transition-delay: 0.1s;
      -o-transition-delay: 0.1s;
      transition-delay: 0.1s;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    .more-info {
      margin-top: 32px;
      display: flex;
      align-items: center;
    }
    .section-top-text {
      text-align: right;
      font-size: 180px;
      color: #edf7fa;
      line-height: 1;
      font-weight: 700;
      letter-spacing: 8px;
      padding: 10px 30px;
      position: absolute;
      right: 10%;
      top: 0;
      @include media(">tablet", "<desktop") {
        font-size: 140px;
        right: 5%;
      }
      @include media("<=tablet") {
        font-size: 100px;
        right: 0%;
      }
      @include media("<=phone") {
        font-size: 50px;
        right: 0;
        letter-spacing: 1px;
        padding: 10px;
        top: 10px;
      }
    }
    .top-left-background-corner {
      opacity: 1;
      position: absolute;
      bottom: 0;
      display: block;
      z-index: 4;
      width: 50%;
      height: 100%;
      background: linear-gradient(to top left, transparent 50%, #edf7fa 50%);
      overflow: hidden;
      -webkit-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
    }
    .anim-two-img {
      position: relative;
      @include media("<=tablet") {
        img {
          width: auto;
          height: auto;
        }
      }
      .building__imageTwo__wrapper {
        height: 100%;
        position: absolute;
        top: -35px;
        right: 0;
        overflow: hidden;
        @include media("<=tablet") {
          width: 200px;
        }
        @include media("<=phone") {
          width: 150px;
          right: 0;
        }
      }
    }
    .container {
      z-index: 5;
      position: relative;
      @include media(">desktop") {
        width: 1100px;
      }
      .row {
        @include media(">phone") {
          padding: 0;
        }
        @include media("<=phone") {
          flex-wrap: wrap;
          flex-direction: column;
        }
      }
    }
    @include media("<=phone") {
      .building-title-sub-content {
        margin-top: 30px;
      }
    }
    .building-title-sub-content h2 {
      font-family: "Oswald", sans-serif;
      font-size: 40px;
      margin: 0;
      font-weight: 400;
      letter-spacing: 3px;
      @include media("<=tablet") {
        font-size: 30px;
      }
      @include media("<=phone") {
        font-size: 30px;
        letter-spacing: 1px;
      }
    }
    .building-title-sub-content h2:after {
      content: "";
      border-color: #000000;
      width: 40px;
      border-width: 1px 0 0 0;
      display: block;
      border-style: solid;
      margin-top: 20px;
    }
    .building-title-sub-content ul {
      margin: 30px 0 0;
      padding: 0;
    }
    .building-title-sub-content ul li {
      display: block;
      margin-bottom: 24px;
    }
    .building-title-sub-content ul li label {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
    .building-title-sub-content ul li h5 {
      font-weight: 400;
      font-family: "Playfair Display", sans-serif;
      font-size: 14px;
      @include media("<=tablet") {
        margin: 5px 0 15px;
      }
    }
  }
  .counter {
    padding-bottom: 80px;
    @include media("<=tablet") {
      padding-bottom: 30px;
    }
    @include media("<=phone") {
      padding-bottom: 20px;
    }
    .container {
      width: 100%;
    }
    .CircularProgressbar {
      width: 195px;
      @include media("<=tablet") {
        width: 120px;
      }
    }
    .counter-radious {
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }
    .counter-radious:after {
      position: absolute;
      border: 5px solid #edf7fa;
      border-radius: 100%;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 85%;
      height: 85%;
      display: block;
      margin: auto;
    }
    .CircularProgressbar .CircularProgressbar-path {
      stroke: #000;
      stroke-width: 0.5;
    }
    .CircularProgressbar .CircularProgressbar-trail {
      stroke: transparent;
    }
    .counter-box {
      text-align: center;
      @include media("<=phone") {
        margin-bottom: 40px;
        width: 100%;
      }
    }
    .counter-content {
      margin-top: 30px;
      @include media("<=tablet") {
        margin-top: 20px;
      }
    }
    .counter-content h2 {
      color: #222;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.1em;
      margin: 0;
      @include media("<=tablet") {
        font-size: 15px;
      }
      @include media("<=phone") {
        font-size: 16px;
      }
    }
    .counter-content p {
      margin: 10px auto 0;
      width: 75%;
      font-size: 14px;
      font-family: "playfair display", sans-serif;
      @include media("<=tablet") {
        width: 100%;
      }
    }
    .CircularProgressbar .CircularProgressbar-text {
      fill: #000;
      font-family: "Playfair Display", serif;
      font-weight: 600;
    }
  }
  .blogs {
    position: relative;
    padding: 100px 0;
    @include media("<=phone") {
      padding: 40px 0;
    }
    .container {
      position: relative;
      z-index: 2;
      .row {
        padding: 0;
        @include media(">desktop") {
          margin: 0 -15px;
        }
      }
    }
    .top-left-anim-text {
      font-size: 180px;
      color: #edf7fa;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 8px;
      position: absolute;
      top: 0;
      left: 7%;
      @include media("<=phone") {
        font-size: 60px;
      }
    }
    .main-title {
      text-align: center;
      margin-bottom: 60px;
      position: relative;
      z-index: 2;
      @include media("<=phone") {
        margin-bottom: 30px;
      }
      .main-title-inner {
        margin: 0 auto;
        @include media(">tablet", ">desktop") {
          max-width: 40%;
        }
        @include media("<tablet") {
          padding: 0 15px;
        }
      }
      h2 {
        font-size: 40px;
        letter-spacing: 0.1em;
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        margin: 0;
        &::after {
          border-bottom: 1px solid #222;
          content: "";
          width: 40px;
          margin: 20px auto;
          display: block;
        }
        @include media("<=phone") {
          font-size: 30px;
          letter-spacing: 1px;
        }
      }
      p {
        font-size: 17px;
        line-height: 26px;
        letter-spacing: 0;
        color: #000;
        font-family: "playfair display", sans-serif;
        margin: 0;
        @include media("<=phone") {
          font-size: 14px;
        }
      }
    }
    .blogs-items {
      padding: 0 25px;
      @include media("<=phone") {
        margin-bottom: 40px;
      }
      .blog-img {
        margin-bottom: 40px;
        overflow: hidden;
        @include media("<=phone") {
          margin-bottom: 20px;
        }
        img {
          display: block;
          width: 100%;
          -webkit-transform: scale(1.05) translateX(-3px);
          -ms-transform: scale(1.05) translateX(-3px);
          transform: scale(1.05) translateX(-3px);
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
          transition: 0.5s;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          &:hover {
            transform: scale(1.05) translateX(3px);
          }
        }
      }
      .blog-content span a {
        font-size: 14px;
        line-height: 1.2;
        color: #000;
        font-family: "playfair display", sans-serif;
        text-decoration: none;
      }
      .blog-content span span {
        font-size: 12px;
        color: #444;
        margin: 0 5px;
      }
      .blog-content h3 {
        margin: 10px 0 0 0;
        font-size: 25px;
        line-height: 1.2em;
        letter-spacing: 0.1em;
        font-weight: 400;
        text-transform: uppercase;
        &::after {
          content: "";
          width: 40px;
          display: block;
          border-bottom: 1px solid #222;
          margin: 20px 0px;
        }
      }
      .blog-content p {
        font-family: "playfair display", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
      }
      .blog-read-more-btn {
        color: #000;
        font-size: 14px;
        text-decoration: none;
        font-family: "playfair display", sans-serif;
        .btn-icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
          position: relative;
          svg {
            max-height: 15px;
            transform: translateY(0);
            transition: 0.2s;
            transition-delay: 0.2s;
          }
          &::after {
            content: "";
            position: absolute;
            background: #bbb;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -10%);
            -ms-transform: translate(-50%, -10%);
            transform: translate(-50%, -10%);
            opacity: 0;
            -webkit-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
          }
        }
      }
      .blog-read-more-btn:hover {
        svg {
          opacity: 0;
          -webkit-transform: translateY(-40%);
          -ms-transform: translateY(-40%);
          transform: translateY(-40%);
          -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s;
        }
        .btn-icon::after {
          opacity: 1;
          -webkit-transition-delay: 0.1s;
          -o-transition-delay: 0.1s;
          transition-delay: 0.1s;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .image-gallery-block {
    position: relative;
    padding: 100px 0;
    @media (max-width: 991px) {
      padding: 40px 0 10px;
    }
    .container {
      position: relative;
      z-index: 2;
      .row {
        padding: 0;
        @include media(">desktop") {
          margin: 0 -15px;
        }
      }
    }
    .main-title {
      text-align: center;
      position: relative;
      z-index: 2;

      p {
        font-size: 17px;
        line-height: 26px;
        letter-spacing: 0;
        color: #000;
        font-family: "playfair display", sans-serif;
        margin: 0;
        @include media("<=phone") {
          font-size: 14px;
        }
      }
    }
    .cards-items {
      margin-bottom: 20px;
      @include media("<=tablet") {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .card-img {
        margin-bottom: 30px;
        overflow: hidden;
        height: 330px;
        @include media("<=tablet") {
          margin-bottom: 20px;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
          transition: 0.5s;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .card-content h3 {
        margin: 10px 0 0 0;
        font-size: 25px;
        line-height: 1.2em;
        letter-spacing: 0.1em;
        font-weight: 400;
        text-transform: uppercase;
        @include media("<=tablet") {
          font-size: 20px;
        }
        &::after {
          content: "";
          width: 40px;
          display: block;
          border-bottom: 1px solid #222;
          margin: 20px 0px;
        }
      }
      .card-content p {
        font-family: "playfair display", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
      }
    }
  }

  @media (max-width: 575px) {
    .brands-img:last-child:nth-child(n - 1) {
        grid-column-end: -2;
      }

      .brands-img:nth-last-child(2):nth-child(n + 1) {
        grid-column-end: 3;
      }
  }

  .brands-logo {
    .brands-grid {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      @media (max-width: 767px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .brands-img {
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          transition: all 0.4s ease-in-out;
          max-height: 150px;
          @media (max-width: 767px) {
            max-height: 100px;
          }
        }
        &:hover {
          img {
            opacity: 0.7;
          }
        }
      }
      .brands-img:last-child:nth-child(n - 1) {
        grid-column-end: -2;
        @media (max-width: 767px) {
            grid-column-end: -2;
        }
        @media (max-width: 575px) {
            grid-column-end: 3;
        }
      }

      .brands-img:nth-last-child(2):nth-child(n + 1) {
        grid-column-end: 3;
        @media (max-width: 767px) {
            grid-column-end: 4;
        }
        @media (max-width: 575px) {
            grid-column-end: 2;
        }
      }
    }
  }
}

.bottom__animation__text {
  display: inline-block;
  opacity: 0;
}
.slick-arrow {
  z-index: 1;
  @media (max-width: 767px) {
    &.slick-prev {
      left: 0px;
    }
    &.slick-next {
      right: 0px;
    }
  }
}
