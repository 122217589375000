@import "~react-image-gallery/styles/css/image-gallery.css";
.our-partner {
    background-image: url('../../assets/about-bg.jpg');
    padding: 50px 0;
    @include media("<=phone") {
        padding: 50px 0;
        .logo {
            flex-wrap: unset !important;
        }
        .logo-img img {
            width: 60px;
        }
    }
    .row {
        padding: 0;
    }
    .logo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .logo-text {
            margin-left: 20px;
            p {
                margin: 0;
                @include media('<=phone') {
                    font-size: 12px;
                }
            }
            h2 {
                letter-spacing: 3px;
                @include media('<=phone') {
                    font-size: 15px;
                }
            }
        }
    }
    .content-right {
        display: flex;
        align-items: center;
        margin-top: 50px;
        img {
            flex-shrink: 0;
            margin-right: 15px;
        }
        p {
            margin: 0;
        }
    }
    .details {
        padding: 50px 0 0;
        .row {
            align-items: center;
        }
        @media (max-width:767px) {
            padding-top: 30px;
        }
    }
    .grop-one-detail {
        .two-image{
            img{
                filter: brightness(0);
            }
        }
        p {
            letter-spacing: .5px;
            @include media("<=phone") {
                font-size: 14px;
            }
        }
        .group-detail {
            margin-top: 50px;
            @media (max-width:767px) {
                margin-top: 30px;
            }
            img {
                margin-bottom: 25px;
            }
        }
        .right-content-box {
            margin: 40px 0 50px;
            padding: 0;
        }
        .right-content-box li {
            display: block;
            padding-left: 10px;
            position: relative;
            margin-bottom: 10px;
            &:after {
                content: "";
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 5px solid #020202;
                width: 0;
                height: 0;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .city-location {
            padding: 0;
            margin-top: 50px;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 13%;
                right: 13%;
                border-bottom: 2px dashed #737373;
                top: 50%;
                z-index: 0;
                @include media("<=phone") {
                    top: 33%;
                }
            }
            li {
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                position: relative;
                z-index: 3;
                width: 25%;
                img {
                    width: 60px;
                    margin: 0 auto;
                    @include media("<=phone") {
                        width: 25px;
                    }
                }
                span {
                    font-size: 14px;
                    @include media("<=phone") {
                        font-size: 11px;
                    }
                }
            }
        }
    }
    .left-progress-years {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media(max-width:767px) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .complete span {
            position: absolute;
            left: 15px;
            top: -5px;
        }
        @include media('<=phone') {
            .complete span {
                display: none;
                left: -20px;
                top: -30px;
            }
            .complete:last-child span,
            .complete:first-child span {
                display: block;
            }
        }
        .complete {
            width: 1px;
            height: 75px;
            position: relative;
            background: #000;
            margin-left: 8px;
            @include media('<=phone') {
                width: 10px;
                height: 1px;
                display: inline-block;
                vertical-align: middle;
            }
            &:last-child {
                height: 0;
            }
        }
        .complete:after {
            content: '';
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            top: 0px;
            left: -7px;
            background: #000;
            z-index: 1;
            @include media('<=phone') {
                width: 7px;
                height: 7px;
                bottom: -3px;
                top: auto;
            }
        }
    }
    .total-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.our-partner.two {
    background-image: url('../../assets/about-bg-2.png');
    padding: 50px 0;
    .customer-count {
        position: relative;
        z-index: 1;
        #right-amin-map {
            position: absolute;
            right: 0;
            top: 0;
            width: 80%;
            z-index: 0;
            opacity: 0.3;
        }
    }
    .city-location.bottom {
        li {
            width: 33.33%;
        }
        &:after {
            left: 16%;
            right: 16%;
        }
    }
}
.main-video-block,
.amenities-details-block,
.gallery-details-block{
    padding: 50px 0;
    @media (max-width:991px) {
        padding: 30px 0;
    }
}
.section-header {
    padding-bottom: 50px;
    text-align: center;
    h3 {
        font-size: 36px;
        color: #283d50;
        font-weight: 500;
        position: relative;
        
    }
    p {
        font-size: 15px;
        margin: 0;
        color: #556877;
    }
    @media (max-width:767px) {
        padding-bottom: 30px;
       h3{
        font-size: 24px;
       }
    }
}
.cbh-logo-img {
    max-width: 300px !important;
    width: 100%;
    margin: auto;
    margin-bottom: 0 !important;
}
.main-video-block {
    position: relative;
    .bg-video-wrap {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        max-width: 1375px;
        margin: 0 auto;
        border-radius: 180px;
        @media (max-width:991px) {
            border-radius: 80px;
        }
        &.youtube-video{
            border-radius: 0;
            max-width: 1300px;
        }
    }
    iframe,
    video {
        // min-height: 100vh;
        z-index: 1;
        // position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        // @media (max-width:991px) {
        //     min-height: auto;
        //     position: relative;
        // }
    }
}
.our-team.about {
    margin-top: 60px;
    margin-bottom: 0;
    padding-bottom: 30px;
    background-color: #19130b;
    @media (max-width:991px) {
        margin-top: 30px;
        padding-bottom: 0;
    }
    .section-header h3 {
        color: #ffffff;
    }
    .section-header p {
        color: #b3bcc3;
    }
    h4{
        color: #ffffff;
        text-align: center;
        margin-bottom: 30px;
    }
}

.our-team {
    margin-bottom: 30px;
    padding: 50px 0;
    @media (max-width:991px) {
        padding: 30px 0;
    }
    .team-card {
        padding: 25px;
        box-shadow: 0px 0px 4px 2px rgb(12 12 12 / 5%);
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 30px;
        @media (max-width:767px) {
            padding: 15px;
        }
        .team-profile-image {
            overflow: hidden;
            display: flex;
            transition: all .3s ease-in;
            &:hover img {
                transform: translateX(-33px);
            }
            img {
                border-radius: 10px;
                transition: all .3s ease-in;
            }
            .social-follow {
                transition: all .3s ease-in;
                flex-shrink: 0;
                margin-left: 5px;
                a {
                    margin-bottom: 5px;
                    display: block;
                    img {
                        width: 30px;
                    }
                }
            }
        }
        .member-designation {
            text-align: center;
            h2 {
                text-transform: uppercase;
                font-size: 25px;
                color: #283d50;
                @include media('<=1024px') {
                    font-size: 20px;
                }
                @include media('<=375px') {
                    font-size: 13px;
                }
            }
            p {
                margin: 0;
                color: #67a9ef;
                @include media('<=tablet') {
                    font-size: 15px;
                }
                @include media('<=375px') {
                    font-size: 10px;
                }
            }
        }
    }
}

#clients {
    padding: 50px 0;
    @media (max-width:991px) {
        padding: 30px 0;
    }
    .clients-wrap {
        border-top: 1px solid #d6eaff;
        border-left: 1px solid #d6eaff;
        padding: 0;
        @media (max-width:575px) {
            display: flex;
            overflow-y: auto;
            align-items: flex-start;
            padding-bottom: 10px;
        }
    }
    .client-logo-item{
        display: flex;
        flex-wrap: wrap;
    }
    .client-logo {
        padding: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #d6eaff;
        border-bottom: 1px solid #d6eaff;
        overflow: hidden;
        background: #fff;
        height: 160px;
        width: 25%;
        img {
            max-height: 70px;
        }
        @media (max-width:767px) {
            padding: 15px;
            height: 75px;
            img{
                max-height: 50px;
            }
        }
        @media (max-width:575px) {
            min-width: 144px;
        }
    }
    img {
        transition: all 0.4s ease-in-out
    }
}